/**
 * Argument provided to react router Navigate function to navigate backward 1 page in history stack
 * See: https://reactrouter.com/docs/en/v6/hooks/use-navigate
 */
export const TO_LAST_VISITED_PAGE = -1
export const TO_PARENT_PATH = '..'

/**
 * Segments are the part of a path pattern between the `/` characters.
 * These are all segments that may appear in a URL.
 */
export const SEGMENTS = {
  '311': '311',
  ACTIVITY: 'activity',
  ACTIVITY_SUMMARY: 'activity-summary',
  CLOSE: 'close',
  CREATE: 'create',
  COMPLIANCE: 'compliance',
  CURB: 'curb',
  DETAILS: 'details',
  DISTRIBUTION: 'distribution',
  DUPLICATE: 'duplicate',
  EDIT: 'edit',
  FEES_AND_REVENUE: 'fees-and-revenue',
  GEOGRAPHIES: 'geographies',
  INVOICING: 'invoicing',
  LIBRARY: 'library',
  LIVE: 'live',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MANAGEMENT: 'management',
  MAP: 'map',
  MAPS: 'maps',
  METRICS: 'metrics',
  MDS_DATA_HEALTH: 'data-health',
  MOBILE: 'mobile',
  MOBILITY: 'mobility',
  MOBILITY_METRICS: 'mobility-metrics',
  OAUTH2: 'oauth2',
  OCCUPANCY: 'occupancy',
  OIDC: 'oidc',
  OPERATOR_DROP_OFFS: 'operator-drop-offs',
  PARKING_INSIGHTS: 'parking-insights',
  PARKING_MAP: 'parking-map',
  PARKING: 'parking',
  PARKING_TIME: 'parking-time',
  PARKING_TIME_DISTRICT: 'parking-time-by-district',
  PLANNING: 'planning',
  POLICIES: 'policies',
  PUBLIC: 'public',
  RATE_ANALYZER: 'rate-analyzer',
  REGULATIONS: 'regulations',
  REPORTING: 'reporting',
  REVENUE: 'revenue',
  ROUTES: 'routes',
  RULES: 'rules',
  TIME_OF_DAY: 'time-of-day',
  TRANSACTIONS: 'transactions',
  TRIP_COUNTS: 'trip-counts',
  TRIP_DISTANCE: 'trip-distance',
  TRIP_DISTANCE_TECH: 'trip-distance-by-tech',
  TRIP_DURATION: 'trip-duration',
  TRIP_SPEED_TECH: 'trip-speed-by-tech',
  TRIPS: 'trips',
}

/**
 * All parameters / dynamic segments that may appear in a URL.
 */
export const PARAMS = {
  REGION_ID: 'regionId',
  POLICY_ID: 'policyId',
}

const MOBILITY_BASE_PATH = `/:${PARAMS.REGION_ID}/${SEGMENTS.MOBILITY}`
const MOBILITY_MAPS_BASE_PATH = `${MOBILITY_BASE_PATH}/${SEGMENTS.MAPS}`
const MOBILITY_REPORTING_BASE_PATH = `${MOBILITY_BASE_PATH}/${SEGMENTS.REPORTING}`

const CURB_BASE_PATH = `/:${PARAMS.REGION_ID}/${SEGMENTS.CURB}`
const CURB_MANAGEMENT_BASE_PATH = `${CURB_BASE_PATH}/${SEGMENTS.MANAGEMENT}`
const CURB_REVENUE_BASE_PATH = `${CURB_BASE_PATH}/${SEGMENTS.REVENUE}`
const CURB_PARKING_INSIGHTS_BASE_PATH = `${CURB_BASE_PATH}/${SEGMENTS.PARKING_INSIGHTS}`
const CURB_REGULATIONS_BASE_PATH = `${CURB_BASE_PATH}/${SEGMENTS.REGULATIONS}`

const MOBILE_BASE_PATH = `/:${PARAMS.REGION_ID}/${SEGMENTS.MOBILE}`
const PUBLIC_BASE_PATH = `/:${PARAMS.REGION_ID}/${SEGMENTS.PUBLIC}`

/**
 * All absolute paths that may appear in a URL.
 * Intermediate paths (e.g. `mobility/maps`) may represent both a full path as well as a collection of child paths
 * _PATH => exact path
 * _ANY => path with star segment
 * @example
 * PATHS.MOBILITY._ANY // "/:regionId/mobility/*"
 * PATHS.MOBILITY.ACTIVITY_SUMMARY // "/:regionId/mobility/activity-summary"
 * PATHS.MOBILITY.POLICIES._PATH // "/:regionId/mobility/policies"
 * PATHS.MOBILITY.POLICIES._ANY // "/:regionId/mobility/policies/*"
 * PATHS.MOBILITY.MAPS.LIVE // "/:regionId/mobility/maps/live"
 */
export const PATHS = {
  CURB: {
    _ANY: `${CURB_BASE_PATH}/*`,
    _ROOT: `${CURB_BASE_PATH}/`,
    GEOGRAPHIES: `${CURB_BASE_PATH}/${SEGMENTS.GEOGRAPHIES}`,
    MANAGEMENT: {
      _ANY: `${CURB_MANAGEMENT_BASE_PATH}/*`,
      ACTIVITY: `${CURB_MANAGEMENT_BASE_PATH}/${SEGMENTS.ACTIVITY}`,
      METRICS: `${CURB_MANAGEMENT_BASE_PATH}/${SEGMENTS.METRICS}`,
    },
    REGULATIONS: {
      _ANY: `${CURB_REGULATIONS_BASE_PATH}/*`,
      MAP: `${CURB_REGULATIONS_BASE_PATH}/${SEGMENTS.MAP}`,
      LIBRARY: `${CURB_REGULATIONS_BASE_PATH}/${SEGMENTS.LIBRARY}`,
    },
    REVENUE: {
      _ANY: `${CURB_REVENUE_BASE_PATH}/*`,
      FEES_AND_REVENUE: `${CURB_REVENUE_BASE_PATH}/${SEGMENTS.FEES_AND_REVENUE}`,
    },
    PARKING_INSIGHTS: {
      _ANY: `${CURB_PARKING_INSIGHTS_BASE_PATH}/*`,
      REVENUE: `${CURB_PARKING_INSIGHTS_BASE_PATH}/${SEGMENTS.REVENUE}`,
      TRANSACTIONS: `${CURB_PARKING_INSIGHTS_BASE_PATH}/${SEGMENTS.TRANSACTIONS}`,
      PARKING_MAP: `${CURB_PARKING_INSIGHTS_BASE_PATH}/${SEGMENTS.PARKING_MAP}`,
      RATE_ANALYZER: `${CURB_PARKING_INSIGHTS_BASE_PATH}/${SEGMENTS.RATE_ANALYZER}`,
      OCCUPANCY: `${CURB_PARKING_INSIGHTS_BASE_PATH}/${SEGMENTS.OCCUPANCY}`,
    },
  },
  LOGIN: `/${SEGMENTS.LOGIN}`,
  LOGOUT: `/${SEGMENTS.LOGOUT}`,
  MOBILE: {
    LIVE: `${MOBILE_BASE_PATH}/${SEGMENTS.LIVE}`,
  },
  MOBILITY: {
    _ANY: `${MOBILITY_BASE_PATH}/*`,
    _ROOT: `${MOBILITY_BASE_PATH}/`,
    ACTIVITY_SUMMARY: `${MOBILITY_BASE_PATH}/${SEGMENTS.ACTIVITY_SUMMARY}`,
    GEOGRAPHIES: `${MOBILITY_BASE_PATH}/${SEGMENTS.GEOGRAPHIES}`,
    INVOICING: `${MOBILITY_BASE_PATH}/${SEGMENTS.INVOICING}`,
    MAPS: {
      _ANY: `${MOBILITY_MAPS_BASE_PATH}/*`,
      '311': `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS['311']}`,
      DISTRIBUTION: `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS.DISTRIBUTION}`,
      LIVE: `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS.LIVE}`,
      PARKING: `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS.PARKING}`,
      OPERATOR_DROP_OFFS: `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS.OPERATOR_DROP_OFFS}`,
      ROUTES: `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS.ROUTES}`,
      TRIPS: `${MOBILITY_MAPS_BASE_PATH}/${SEGMENTS.TRIPS}`,
    },
    MDS_DATA_HEALTH: `${MOBILITY_BASE_PATH}/${SEGMENTS.MDS_DATA_HEALTH}`,
    POLICIES: {
      _PATH: `${MOBILITY_BASE_PATH}/${SEGMENTS.POLICIES}`,
      _ANY: `${MOBILITY_BASE_PATH}/${SEGMENTS.POLICIES}/*`,
      COMPLIANCE: {
        _PATH: `${MOBILITY_BASE_PATH}/${SEGMENTS.POLICIES}/${SEGMENTS.COMPLIANCE}`,
        DETAILS: `${MOBILITY_BASE_PATH}/${SEGMENTS.POLICIES}/${SEGMENTS.COMPLIANCE}/${SEGMENTS.DETAILS}`,
      },
      LIBRARY: `${MOBILITY_BASE_PATH}/${SEGMENTS.POLICIES}/${SEGMENTS.LIBRARY}`,
    },
    REPORTING: {
      _ANY: `${MOBILITY_REPORTING_BASE_PATH}/*`,
      MOBILITY_METRICS: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.MOBILITY_METRICS}`,
      PARKING_TIME: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.PARKING_TIME}`,
      PARKING_TIME_DISTRICT: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.PARKING_TIME_DISTRICT}`,
      TIME_OF_DAY: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.TIME_OF_DAY}`,
      TRIP_COUNTS: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.TRIP_COUNTS}`,
      TRIP_DISTANCE: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.TRIP_DISTANCE}`,
      TRIP_DISTANCE_TECH: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.TRIP_DISTANCE_TECH}`,
      TRIP_DURATION: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.TRIP_DURATION}`,
      TRIP_SPEED_TECH: `${MOBILITY_REPORTING_BASE_PATH}/${SEGMENTS.TRIP_SPEED_TECH}`,
    },
  },
  OAUTH2: {
    LOGIN: {
      CLOSE: `/${SEGMENTS.OAUTH2}/${SEGMENTS.LOGIN}/${SEGMENTS.CLOSE}`,
    },
  },
  OIDC: {
    LOGIN: `/${SEGMENTS.OIDC}/${SEGMENTS.LOGIN}`,
  },
  PUBLIC: {
    _ANY: `${PUBLIC_BASE_PATH}/*`,
    ROUTES: `${PUBLIC_BASE_PATH}/${SEGMENTS.ROUTES}`,
    REGULATIONS: `${PUBLIC_BASE_PATH}/${SEGMENTS.REGULATIONS}`,
  },
  ROOT: '/',
}
